<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
                <div class="h-b"><el-button type="primary" size="small" @click="addRole" v-right-code="'Sysrole:Save'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="saveRole" v-right-code="'Sysrole:Save'">保存</el-button> </div>
            </div>
        </div>
        
        <div class="form-list">
            <el-form :model="dataSource" ref="_roleForm" :rules="roleValidateRule">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span>角色名称：
                    </el-col>
                    <el-col :span="9">
                        <el-form-item prop="RoleName">
                            <el-input v-model="dataSource.RoleName" :maxlength="30" :minlength="2" placeholder="角色名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">租户：</el-col>
                    <el-col :span="9">
                        <el-form-item prop="OptEnterpriseIdList">
                            <el-select v-model="dataSource.OptEnterpriseIdList" multiple collapse-tags placeholder="请选择" :maxlength="200" @change="initialEnterprisePermTree">
                                <el-option v-for="item in enterpriseList" 
                                    :key="item.OptEnterpriseID"
                                    :label="item.EnterpriseName" 
                                    :value="item.OptEnterpriseID">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        角色描述：
                    </el-col>
                    <el-col :span="18">
                        <el-form-item prop="RoleDesc">
                            <el-input v-model="dataSource.RoleDesc" :maxlength="500" placeholder="描述" type="textarea"
                                    :autosize="{minRows: 5, maxRows: 20}"></el-input>
                                    <span class="fixed-numer">{{(dataSource.RoleDesc||'').length}}/500</span>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <!-- <el-form-item v-if="false">
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span>角色类型：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="SysBizType">
                            <el-select v-model="dataSource.SysBizType" :maxlength="200" @change="sysBizTypeChange(dataSource.SysBizType)" :disabled="!dataSource.IsChangeSysBizType">
                                <el-option v-for="item in getBizTypeList()" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item> -->
                
            </el-form>
        </div>
        <el-tabs type="border-card">
            <!-- <el-tab-pane label="菜单">
                <el-tree :data="dataSource.EnterpriseMenuTree"
                    show-checkbox
                    node-key="id"
                    ref="_EnterpriseTree"
                    :default-checked-keys="checked_keys"
                    highlight-current>
                </el-tree>
            </el-tab-pane> -->
            <el-tab-pane label="平台应用">
                <el-tree :data="dataSource.PlatformPermTree"
                    show-checkbox
                    node-key="ShowSysMenuId"
                    ref="_PlatformPermTree"
                    :default-checked-keys="checkedPlatformPermKeys"
                    :props="treeProps"
                    highlight-current>
                    <span slot-scope="{ node, data }" class="custom-tree-node">
                        <span class="tooltip">{{ data.MenuName }}</span>
                    </span>
                </el-tree>
            </el-tab-pane>
            <el-tab-pane label="租户应用">
                <el-tree :data="dataSource.EnterprisePermTree"
                    show-checkbox
                    node-key="ShowSysMenuId"
                    ref="_EnterprisePermTree"
                    :default-checked-keys="checkedEnterprisePermKeys"
                    :props="treeProps"
                    highlight-current>
                </el-tree>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                roleValidateRule: {
                    RoleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' } ],
                    RoleDesc: [ { required: false, message: '请输入角色描述', trigger: 'blur' } ],
                    SysBizType: [{ required: true, message: '请选择角色类型', trigger: 'blur' }],
                },
                tdCount:4,
                sysBizTypeList: [
                    { label: '系统', value: 100 },
                    { label: '业务', value: 200 }
                ],
                treeProps: {
                    children: 'Children',
                    label: 'MenuName'
                },
                enterpriseList:[],
                checkedEnterpriseIdList:[]
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
            },
            dataSource:{
                RoleOnWarehouseTree:[]
            }
        },
        watch:{
        },
        computed:{
            checked_keys() {
                return this.dataSource.PermIds ? this.dataSource.PermIds.split(";") : [];
            },
            checkedPlatformPermKeys() {
                return this.dataSource.PlatformPermIds ? this.dataSource.PlatformPermIds.split(";") : [];
            },
            checkedEnterprisePermKeys() {
                return this.dataSource.EnterprisePermIds ? this.dataSource.EnterprisePermIds.split(";") : [];
            },
        },
        mounted(){
            this.initialEnterpriseList();
        },
        methods: {
            addRole() {
                this.Event.$emit("onAddRole");
            },
            saveRole() {
                this.$refs["_roleForm"].validate((valid) =>{
                    if (valid) {
                        var obj = this.Utils.cloneObj(this.dataSource);
                        obj.PlatformPermIds = this.$refs._PlatformPermTree.getCheckedKeys(true).join(";");
                        obj.EnterprisePermIds = this.$refs._EnterprisePermTree.getCheckedKeys(true).join(";");
                        this.$ajax.send(window.AppConfig.apiBaseUrl+"omsapi/sysroleextend/save", "post", obj, (data) => {
                            (!this.dataSource.SysRoleId) && (this.dataSource.SysRoleId = data.Result);
                            this.$parent.syncDataSource();
                            this.Event.$emit("reloadRolePageList");
                            this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        this.Utils.messageBox("存在必填信息未输入.", "error");
                        return false;
                    }
                });
            },
            sysBizTypeChange: function (sysBizType) {
                var _this = this;
                _this.$ajax.send(window.AppConfig.apiBaseUrl+"omsapi/sysrole/initrolelist", "get", { sysBizType: sysBizType }, (data) => {
                    if(data.IsSuccess){
                        _this.dataSource.EnterprisePermTree = data.Result.EnterprisePermTree;
                    }
                });
            },
            getBizTypeList(){
                var superSysBizTypeList=[{ label: '系统', value: 100 }, { label: '业务', value: 200}, { label: '通用', value: 300 }];
                if(this.dataSource.IsSystemRole)
                    return superSysBizTypeList;
                else
                    return this.sysBizTypeList;
            },
            initialEnterpriseList () {
                var _this = this;
                _this.$ajax.send('omsapi/optenterprise/getalllist', 'get',{}, data => {
                    _this.enterpriseList = data.Result;
                });
            },
            initialEnterprisePermTree () {
                var _this = this;
                _this.$ajax.send('omsapi/sysroleextend/getpermtreelist', 'post',_this.dataSource, data => {
                    _this.dataSource.EnterprisePermTree = data.Result;
                });
            },
        }
    }
</script>

<style scoped>
    .t_w {
        width: 100%;
    }

        .t_w tr td {
            padding: 4px 8px;
        }
</style>